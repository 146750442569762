import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Video from '../../components/Video';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Callout from '../../components/Callout';
import Quote from '../../components/Quote';
import Hero from '../../components/Hero';
import ToggleClassOnVisible from '../../components/ToggleClassOnVisible';
import LoginLogoutBlock from '../../components/LoginLogoutBlock';
import { useTranslation } from 'react-i18next';

import sectionGuide from '../../images/get-started-images/img-employees-guide.jpg';

const EmployeesPage = ({ pageContext }) => {
  const { t } = useTranslation('employees');
  const CC_DISCLAIMER = ['ir', 'uk', 'co', 'mx', 'in'];

  const textOverlay = (
    <div className="employees__text_overlay_container">
      <Container fullWidth={true}>
        <div className="employees__text_overlay_inner_container">
          <div className="employees__text_overlay_section text-bold color-yellow">
            {t('heroSection')}
          </div>
          <span className="employees__text_overlay_title">
            {t('heroTitle1')}{' '}
          </span>
          <span className="employees__text_overlay_title2 text-bold color-light-blue">
            {t('heroTitle2')}
          </span>
        </div>
      </Container>
    </div>
  );

  const heroImages = [
    { image: 'img-lead-employees-1.jpg', text: '', title: '' },
    { image: 'img-lead-employees-2.jpg', text: '', title: '' },
    { image: 'img-lead-employees-3.jpg', text: '', title: '' },
    { image: 'img-lead-employees-4.jpg', text: '', title: '' },
  ];

  const removeARDX = true;

  return (
    <Layout title="Employees" className={pageContext.lang}>
      <Hero mediaSource={heroImages} textOverlay={textOverlay} />
      <Section>
        <Container>
          <div className="banner">
            <p
              dangerouslySetInnerHTML={{ __html: t('gettingStartedBanner1') }}
            />
          </div>
        </Container>
      </Section>
      <Section className="pb-1">
        <Container>
          <Row>
            <Column size={8}>
              <h2>{t('gettingStartedIntroHeading')}</h2>
              <p>{t('gettingStartedIntroBody')}</p>
            </Column>
            <Column size={4}>
              <LoginLogoutBlock ardx={removeARDX} />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="mb-2">
        <Container>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row>
              <Column size={7}>
                <Video
                  src={t('videoIntro')}
                  poster="video-still-employees-volunteer.jpg"
                  title={t('gettingStartedVideo1Heading')}
                  autoplay
                />
                {CC_DISCLAIMER.includes(pageContext.lang) && (
                  <h4
                    style={{
                      textAlign: 'center',
                      fontSize: '1rem',
                      marginTop: '0.5rem',
                    }}
                  >
                    {t('ccDisclaimer')}
                  </h4>
                )}
              </Column>
              <Column size={5}>
                <h2>{t('gettingStartedVideo1Heading')}</h2>
                <p>{t('gettingStartedVideo1Body')}</p>
              </Column>
            </Row>
            <Row>
              <Column size={7}></Column>
            </Row>
          </ToggleClassOnVisible>
        </Container>
      </Section>
      <ToggleClassOnVisible extraClasses="appear-on-visible">
        <Section
          className="section--guide"
          style={{ backgroundImage: `url(${sectionGuide})` }}
        >
          <Container>
            <Row>
              <Column size={6}>
                <h2>{t('gettingStartedGuideHeading')}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('gettingStartedGuideBody'),
                  }}
                />
                <Button to={t('volunteerGuidePDF')} target="_blank">
                  <Icon name="download" marginRight />
                  {t('gettingStartedGuideButton')}
                </Button>
              </Column>
            </Row>
          </Container>
        </Section>
      </ToggleClassOnVisible>
      <ToggleClassOnVisible extraClasses="appear-on-visible">
        <Section>
          <Container>
            <Row>
              <Column size={7}>
                <Video
                  src={t('videoTraining')}
                  poster="video-still-employees-ed-webinar.jpg"
                  title={t('gettingStartedVideo1Heading')}
                  autoplay
                />
              </Column>
              <Column size={5}>
                <h2>{t('gettingStartedVideo2Heading')}</h2>
                <p>{t('gettingStartedVideo2Body')}</p>
              </Column>
            </Row>
          </Container>
        </Section>
      </ToggleClassOnVisible>
      <ToggleClassOnVisible extraClasses="appear-on-visible">
        <Section>
          <Container fullWidth>
            <Callout
              to="https://abbottegc.benevity.org/user/login"
              alt={t('gettingStartedBannerAlt')}
              className="callout--banner"
            >
              <div
                className="text-sans-serif"
                dangerouslySetInnerHTML={{ __html: t('gettingStartedBanner2') }}
              />
            </Callout>
          </Container>
        </Section>
      </ToggleClassOnVisible>
      <ToggleClassOnVisible extraClasses="appear-on-visible">
        <Quote
          quote={t('gettingStartedQuoteBody')}
          author={t('gettingStartedQuoteAuthor')}
          authorDescription={t('gettingStartedQuoteAuthorDescription')}
        />
      </ToggleClassOnVisible>
    </Layout>
  );
};

export default EmployeesPage;
